import { combineReducers } from "redux";
import _ from "lodash";
import { reducer as formReducer } from "redux-form";

import { loadState, reset, saveState } from "./localStorage";
import { API_KEY } from "../components/Common/constant";

import {
  HM_PARTNER_LOGIN,
  HM_PARTNER_LOGOUT,
  SET_SESSION_TOKEN,
  UNSET_SESSION_TOKEN,
  DEVICE_SIZE_REDUCER,
  SET_COMMON_MODAL_DETAILS,
  SET_COMMON_EDIT_MODAL_DETAILS,
  SET_FULL_PAGE_LOADER,
  PARTNER_DETAILS,
  SET_CODE_ADD_MODAL_DETAILS,
  ACTION_LOGOUT,
} from "../actions";

// -------- imports of reducers from various components start ----------

import { enterPrisePlan, editPlanModal } from "../components/plan/reducers";
import {
  userList,
  logListReducers,
  currentPlanDetails,
} from "../components/userlist/reducers";

import { reportedContactList } from "../components/reportedcontact/reducers";

// --------  imports of reducers from various components end  ----------

const LoginReducer = (state, action) => {
  state = loadState();

  if (state == undefined || !state.isLoggedIn) {
    state = {
      ...state,
      isLoggedIn: false,
    };
  }

  if (action.type === HM_PARTNER_LOGIN) {
    state.isLoggedIn = action.payload;
  }

  if (action.type === HM_PARTNER_LOGOUT) {
    state.isLoggedIn = action.payload;
  }

  if (action.type === ACTION_LOGOUT) {
    state.isLoggedIn = action.payload;
  }

  saveState(state);
  return state.isLoggedIn;
};

const tokenReducer = (state, action) => {
  state = loadState();

  if (state.session == null || state.session.apiKey === "")
    state = {
      ...state,
      session: {
        apiKey: API_KEY,
        session_token: "",
        id: null,
        email: "",
        name: "",
      },
    };

  if (action.type === SET_SESSION_TOKEN) {
    if (action.payload.session_token) {
      state.session.session_token = action.payload.session_token;
      state.session.id = action.payload.id;
      state.session.email = action.payload.email;
      state.session.name = action.payload.partner_name;
    }
  }

  if (action.type === UNSET_SESSION_TOKEN) {
    state.session.session_token = action.payload.session_token;
    state.session.id = action.payload.id;
    state.session.email = action.payload.email;
    state.session.name = action.payload.name;
    reset();
  }
  if (action.type === ACTION_LOGOUT) {
    state.session.session_token = action.payload.session_token;
    state.session.id = action.payload.id;
    state.session.email = action.payload.email;
    state.session.name = action.payload.name;
    reset();
  }

  // ----- seting local storage
  saveState(state);
  return state.session;
};

const partnerDetails = (state = {}, action) => {
  if (action.type === PARTNER_DETAILS) return action.payload;
  return state;
};

const deviceSize = (state = {}, action) => {
  if (action.type === DEVICE_SIZE_REDUCER) return action.payload;
  return state;
};

const commonAddModalReducer = (
  state = { show: false, calledFrom: "" },
  action
) => {
  if (action.type === SET_CODE_ADD_MODAL_DETAILS) return action.payload;
  return state;
};

const commonModalDetailsReducer = (
  state = { show: false, calledFrom: "" },
  action
) => {
  if (action.type === SET_COMMON_MODAL_DETAILS) return action.payload;
  return state;
};

const commonEditModalDetailsReducer = (
  state = { show: false, calledFrom: "" },
  action
) => {
  if (action.type === SET_COMMON_EDIT_MODAL_DETAILS) return action.payload;
  return state;
};

const fullPageLoaderReducer = (state = false, action) => {
  if (action.type === SET_FULL_PAGE_LOADER) return action.payload;
  return state;
};

const rootReducer = combineReducers({
  form: formReducer,

  // Partner Details
  partnerDetails: partnerDetails,

  // common
  isLoggedIn: LoginReducer,
  session: tokenReducer,
  deviceSize,
  commonAddModalReducer,
  commonModalDetailsReducer,
  commonEditModalDetailsReducer,
  fullPageLoaderReducer,

  enterPrisePlan: enterPrisePlan,
  editPlanModal: editPlanModal,

  userList: userList,
  logListReducers: logListReducers,
  currentPlanDetails: currentPlanDetails,

  reportedContactList: reportedContactList,
});

export default rootReducer;
