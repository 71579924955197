import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { format } from "date-fns";
import EditPlan from "./editPlan";
import { fetchEnterpriseData, editPlanModalOpen } from "./actions";
import Loader from "../Common/Loader";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";

const Plan = (props) => {
  const { isLoggedIn, session, enterPrisePlan, editPlanModal } = props;
  const Navigate = useNavigate();
  const Location = useLocation();
  const [activeTab, setActiveTab] = useState("free");
  const [expandedPlans, setExpandedPlans] = useState({});
  const contentRefs = useRef({});

  useEffect(() => {
    props.fetchEnterpriseData(session, (res) => {});
  }, []);

  const editModal = (plandetails) => {
    console.log("plandetails", plandetails);
    props.editPlanModalOpen({
      show: true,
      data: plandetails,
    });
  };

  const togglePlan = (planId) => {
    setExpandedPlans((prev) => ({
      ...prev,
      [planId]: !prev[planId], // Toggle the expanded state
    }));
  };

  const _renderPlan = () => {
    const groupedData = _.groupBy(enterPrisePlan.data, "plangroup"); // Group plans by plangroup

    return Object.keys(groupedData).map((plangroup, groupIndex) => (
      <React.Fragment key={groupIndex}>
        <tr className="bg-gray-200">
          <td
            colSpan="8"
            className="border-b border-slate-100 dark:border-slate-700 p-2 text-slate-700 text-left font-bold"
          >
            {_.startCase(plangroup)}
          </td>
        </tr>

        {groupedData[plangroup].map((code, planIndex) => (
          <tr key={`${groupIndex}-${planIndex}`}>
            <td className="border-b border-slate-100 dark:border-slate-700 p-1.5 text-slate-500 text-left pl-4">
              <div>
                {code.sku === "custom_yearly"
                  ? `${code.name} Yearly`
                  : code.sku === "custom_monthly"
                  ? `${code.name} Monthly`
                  : code.name}
              </div>
              <div className="text-sm text-gray-500">
                Price - {code.price !== "-1" ? `$${code.price}` : ""}
              </div>
              <div className="text-sm text-gray-500">
                Validity - {code.validity}
              </div>
            </td>
            <td className="border-b border-slate-100 dark:border-slate-700 p-1.5 text-slate-500">
              {code.contactcredit}
            </td>
            <td className="border-b border-slate-100 dark:border-slate-700 p-1.5 text-slate-500">
              {code.personal_email_credits}
            </td>
            <td className="border-b border-slate-100 dark:border-slate-700 p-1.5 text-slate-500">
              {code.bus_email_credits}
            </td>
            <td className="border-b border-slate-100 dark:border-slate-700 p-1.5 text-slate-500">
              {code.technology_credits}
            </td>
            <td className="border-b border-slate-100 dark:border-slate-700 p-1.5 text-slate-500">
              <div>Per Min - {code.api_rate_min || 0}</div>
              <div>Per Hr - {code.api_rate_hr || 0}</div>
              <div>Per Day - {code.api_rate_day || 0}</div>
            </td>
            <td className="border-b border-slate-100 dark:border-slate-700 p-1.5 text-slate-500">
              {code.csv_enr_day}
            </td>
            <td className="border-b border-slate-100 dark:border-slate-700">
              <div className=" p-1.5 text-slate-500 justify-center flex items-center">
                <a
                  className="text-kipplocolor cursor-pointer"
                  onClick={() => editModal(code)}
                >
                  Edit
                </a>
              </div>
            </td>
          </tr>
        ))}
      </React.Fragment>
    ));
  };

  const getPlanHeight = (planId) => {
    const ref = contentRefs.current[planId];
    return expandedPlans[planId] && ref?.current ? ref.current.scrollHeight : 0;
  };

  const _renderPlanTab = () => {
    const groupedData = _.groupBy(enterPrisePlan.data, "plangroup"); // Group plans by plangroup
    // Default to the first tab

    return (
      <div>
        {/* Tabs Navigation */}
        <ul
          className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400"
          role="tablist"
        >
          {Object.keys(groupedData).map((plangroup, index) => (
            <li className="me-2" key={`tab-${index}`}>
              <button
                className={`inline-block p-4 rounded-t-lg ${
                  activeTab === plangroup
                    ? "text-white bg-kipplobg active"
                    : "hover:text-white hover:bg-kipplohover"
                }`}
                onClick={() => setActiveTab(plangroup)}
                id={`tab-${index}`}
                role="tab"
                aria-controls={`content-${index}`}
                aria-selected={activeTab === plangroup}
              >
                {_.startCase(plangroup)}
              </button>
            </li>
          ))}
        </ul>

        {/* Tabs Content */}
        <div className="">
          {Object.keys(groupedData).map((plangroup, index) => (
            <div
              key={`content-${index}`}
              id={`content-${index}`}
              className={`${activeTab === plangroup ? "block" : "hidden"}`}
              role="tabpanel"
              aria-labelledby={`tab-${index}`}
            >
              {/* Table Body */}

              {groupedData[plangroup].map((code, planIndex) => {
                const planId = `${plangroup}-${planIndex}`;
                if (!contentRefs.current[planId]) {
                  contentRefs.current[planId] = React.createRef();
                }
                return (
                  <React.Fragment key={planId}>
                    {/* Main Row */}
                    <table className="w-full table-auto ">
                      <tbody>
                        <tr className="shadow-sm ">
                          <td
                            className=" my-4 p-2 flex items-center justify-between cursor-pointer w-3/4"
                            onClick={() => togglePlan(planId)}
                          >
                            <span className="text-kipplocolor font-semibold">
                              {code.name}
                            </span>
                          </td>
                          <td
                            className=" my-4 p-2 w-1/4 cursor-pointer"
                            onClick={() => togglePlan(planId)}
                          >
                            <div className="flex justify-end">
                              {expandedPlans[planId] ? (
                                <ChevronUpIcon className="h-6 w-6 text-kipplocolor" />
                              ) : (
                                <ChevronDownIcon className="h-6 w-6 text-kipplocolor" />
                              )}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* Expandable Details */}
                    {expandedPlans[planId] && (
                      <table className="w-full table-auto ">
                        <tbody>
                          {/* Expanded Content Header */}
                          <tr
                            style={{
                              overflow: "hidden",
                              transition: "max-height 0.5s ease-in-out",
                            }}
                          >
                            <th className=" p-2 text-left font-semibold">
                              Name
                            </th>
                            <th className=" p-2 text-left font-semibold">
                              Contact Credits
                            </th>
                            <th className=" p-2 text-left font-semibold">
                              Personal Email Credits
                            </th>
                            <th className=" p-2 text-left font-semibold">
                              Business Email Credits
                            </th>
                            <th className=" p-2 text-left font-semibold">
                              Technology Credits
                            </th>
                            <th className=" p-2 text-left font-semibold">
                              API Rates
                            </th>
                            <th className=" p-2 text-left font-semibold">
                              CSV Enrollments
                            </th>
                            <th className=" p-2 text-left font-semibold">
                              Price
                            </th>
                            <th className=" p-2 text-left font-semibold">
                              Validity
                            </th>
                          </tr>
                          {/* Expanded Content Row */}
                          <tr>
                            <td className=" p-2">{code.name}</td>
                            <td className=" p-2">{code.contactcredit}</td>
                            <td className=" p-2">
                              {code.personal_email_credits}
                            </td>
                            <td className=" p-2">{code.bus_email_credits}</td>
                            <td className=" p-2">{code.technology_credits}</td>
                            <td className=" p-2">
                              <div>Per Min: {code.api_rate_min || 0}</div>
                              <div>Per Hr: {code.api_rate_hr || 0}</div>
                              <div>Per Day: {code.api_rate_day || 0}</div>
                            </td>
                            <td className=" p-2">{code.csv_enr_day}</td>
                            <td className=" p-2">
                              {code.price !== "-1" ? `$${code.price}` : "N/A"}
                            </td>
                            <td className=" p-2">{code.validity}</td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    );
  };

  //console.log('enterPrisePlan',enterPrisePlan);

  return (
    <>
      <article className="container px-4 mx-auto grid">
        <div className="grid grid-cols-4 mt-6">
          <div className="col-span-4">
            <div>
              {enterPrisePlan.data ? (
                enterPrisePlan.data.length > 0 ? (
                  <>
                    <div className="not-prose relative bg-slate-100 rounded-xl overflow-hidden">
                      <div className="relative rounded-xl overflow-auto">
                        <div className="shadow-sm overflow-hidden">
                          {/* {_renderPlanTab()} */}

                          <table className="border-collapse table-fixed w-full text-[16px]">
                            <thead>
                              <tr>
                                <th className="border-b font-medium p-1.5 text-left pl-4">
                                  Name
                                </th>

                                <th className="border-b font-medium p-1.5 text-left">
                                  Contact Credits
                                </th>
                                <th className="border-b font-medium p-1.5  text-left">
                                  Personal Email Credits
                                </th>
                                <th className="border-b font-medium p-1.5  text-left">
                                  Business Email Credits
                                </th>
                                <th className="border-b font-medium p-1.5  text-left">
                                  Technology Credits
                                </th>
                                <th className="border-b font-medium p-1.5  text-left">
                                  API Rate
                                </th>
                                <th className="border-b font-medium p-1.5  text-left">
                                  CSV Enrichment
                                </th>
                                <th className="border-b font-medium p-1.5 text-left" />
                              </tr>
                            </thead>
                            <tbody className="bg-white">{_renderPlan()}</tbody>
                          </table>
                        </div>
                      </div>
                      <div className="absolute inset-0 pointer-events-none border border-black/5 rounded-xl dark:border-white/5"></div>
                    </div>
                  </>
                ) : (
                  <div className="flex items-center justify-center text-base ">
                    No Plan Found
                  </div>
                )
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </article>
      {editPlanModal && editPlanModal.show ? <EditPlan /> : ""}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    isLoggedIn: state.isLoggedIn,
    enterPrisePlan: state.enterPrisePlan,
    editPlanModal: state.editPlanModal,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchEnterpriseData, editPlanModalOpen }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Plan);
