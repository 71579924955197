import React, { useState, useEffect, useRef, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { Dialog, Transition } from "@headlessui/react";
import { Field, reduxForm, FieldArray } from "redux-form";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import {
  renderInputField,
  renderCheckbox,
  CustomSpinner,
  renderInputFieldTextArea,
  renderSelect,
} from "../Common/base-component";
import { DATE_FORMAT } from "../Common/constant";
import LocalStrings from "../../languages/LocalStrings";
import { Toastify } from "../Common/Toastify";

import {
  updatePlan,
  fetchEnterpriseData,
  editPlanModalOpen,
} from "../plan/actions";
import Cross from "../../assets/img/icons/cross-icon.svg";

const EditPlan = (props) => {
  const { session, editPlanModal, handleSubmit } = props;
  const [processLoading, setProcessLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ message: "" });
  const Navigate = useNavigate();
  const basicArr = [
    { key: "all_filters", text: "All Filters" },
    { key: "technology_5", text: "Technology filter limit to 5" },
  ];

  const closeModal = () => {
    props.editPlanModalOpen({ show: false, data: {} });
  };

  const onSubmitForm = (values) => {
    //console.log("Submitted values:", values);
    let newFeature = [];
    const features = Array.isArray(values.new_features_json)
      ? values.new_features_json
      : [];
    if (features.length > 0) {
      features.map((row, index) => {
        if (row.value && row.name) {
          let obj = { value: row.value, name: row.name };
          //console.log("obj", obj);
          newFeature.push(obj);
        }
      });
    }
    //console.log("newFeature", newFeature);
    if (newFeature.length > 0) {
      values.new_features_json = JSON.stringify(newFeature);

      setProcessLoading(true);
      props.updatePlan(session, values, values.planid, (res) => {
        // console.log("res", res);
        if (res.success === 1) {
          props.fetchEnterpriseData(session, (obj) => {
            setProcessLoading(false);
            //console.log("obj", obj);
            Toastify("success", "Successfully updated");
            //closeModal();
          });
        } else {
          setProcessLoading(false);
          Toastify("error", res.message);
          //closeModal();
        }
      });
      closeModal();
    } else {
      Toastify("error", "Feature can not be empty");
    }
  };

  const renderFieldtd = ({ fields, meta: { error, submitFailed } }) => (
    <div className="">
      <div className="flex justify-between items-center">
        <div className=" text-base font-semibold">Feature Lists</div>
        <button
          type="button"
          className="cursor-pointer bg-gray-300 px-2 py-3 rounded text-sm font-semibold"
          onClick={() => fields.push({})}
        >
          +Add more feature
        </button>
        {submitFailed && error && <span>{error}</span>}
      </div>
      {fields.map((rowItem, index) => {
        var currentInxedData = fields.get(index);
        let showDelBtn = index === 0 ? " hidden" : "";
        return (
          <>
            <div key={index} className="flex gap-2 items-center">
              <div className="w-2/5">
                <Field
                  key={`${rowItem}.value`}
                  name={`${rowItem}.value`}
                  placeholder="Value"
                  type="text"
                  component={renderInputField}
                />
              </div>
              <div className=" w-1/2">
                <Field
                  key={`${rowItem}.name`}
                  name={`${rowItem}.name`}
                  placeholder="Name"
                  type="text"
                  component={renderInputField}
                />
              </div>

              <div className={showDelBtn.trim() == "hidden" ? "hidden" : ""}>
                <button type="button" onClick={() => fields.remove(index)}>
                  <svg
                    className="w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    role="img"
                    aria-hidden="true"
                  >
                    <path
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
  //console.log("editPlanModal", editPlanModal);
  let plandt = editPlanModal?.data;
  const basicOptions = basicArr.map((item) => ({
    value: item.key,
    text: item.text,
  }));
  return (
    <>
      <Transition.Root show={editPlanModal.show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          {/* ----- back drop child ----- */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* ----- modal child ----- */}
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="transform transition-all">
                  <>
                    <div
                      className="bg-white rounded-lg p-4 text-left
                        overflow-hidden- shadow-xl transform transition-all
                        sm:my-8 lg:mt-20 lg:mb-8 ml-0 md:ml-32 sm:max-w-screen-md
                        sm:w-full sm:p-6"
                    >
                      <header className="grid grid-cols-2">
                        {/* ----- title ----- */}
                        <div className="col-span-1 flex justify-start">
                          <p className="mb-2 text-lg font-semibold text-gray-700"></p>
                        </div>

                        {/* ----- close button ----- */}
                        <div className="col-span-1 flex justify-end">
                          <button
                            type="button"
                            className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded hover: hover:text-gray-700"
                            aria-label="close"
                            onClick={closeModal}
                          >
                            <svg
                              className="w-4 h-4"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              role="img"
                              aria-hidden="true"
                            >
                              <path
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                                fillRule="evenodd"
                              ></path>
                            </svg>
                          </button>
                        </div>
                      </header>
                      {/* ----- rendering modal body anr footer section conditionally ----- */}
                      <>
                        <div>
                          {plandt.sku == "custom_yearly"
                            ? plandt.name + " Yearly"
                            : plandt.sku == "custom_monthly"
                            ? plandt.name + " Monthly"
                            : plandt.name}
                        </div>
                        <span className="text-sm text-red-800">
                          {errorMessage.message}
                        </span>
                        <form
                          className=""
                          onSubmit={handleSubmit(onSubmitForm)}
                        >
                          <div className="my-1">
                            <Field
                              name="details"
                              label="Details"
                              component={renderInputFieldTextArea}
                              placeholder="details"
                              mandatory
                            />
                          </div>
                          <div className="my-1">
                            <Field
                              name="validity"
                              label="Validity"
                              component={renderInputField}
                              placeholder="validity"
                              mandatory
                            />
                          </div>
                          <div className="my-1">
                            <Field
                              name="contactcredit"
                              label="Contact Credit"
                              component={renderInputField}
                              mandatory
                            />
                          </div>
                          <div className="my-1">
                            <Field
                              name="personal_email_credits"
                              label="Personal Email Credit"
                              component={renderInputField}
                              mandatory
                            />
                          </div>
                          <div className="my-1">
                            <Field
                              name="bus_email_credits"
                              label="Business Email Credit"
                              component={renderInputField}
                              mandatory
                            />
                          </div>
                          <div className="my-1">
                            <Field
                              name="technology_credits"
                              label="Technology Credit"
                              component={renderInputField}
                              mandatory
                            />
                          </div>
                          <div className="my-1">
                            <Field
                              name="api_rate_min"
                              label="Api rate(min)"
                              component={renderInputField}
                              mandatory
                            />
                          </div>
                          <div className="my-1">
                            <Field
                              name="api_rate_hr"
                              label="Api rate(hr)"
                              component={renderInputField}
                              mandatory
                            />
                          </div>
                          <div className="my-1">
                            <Field
                              name="api_rate_day"
                              label="Api rate(day)"
                              component={renderInputField}
                              mandatory
                            />
                          </div>
                          <div className="my-1">
                            <Field
                              name="api_rate_monthly"
                              label="Api rate(monthly)"
                              component={renderInputField}
                            />
                          </div>
                          <div className="my-1">
                            <Field
                              name="csv_enr_day"
                              label="Csv Enricment Per day"
                              component={renderInputField}
                              mandatory
                            />
                          </div>
                          <div className="my-1">
                            <Field
                              name="basicfilters_limits"
                              label="Basic Filters Limit"
                              component={renderSelect}
                              opts={basicOptions}
                            />
                          </div>
                          <div className="my-1">
                            <Field
                              name="max_selection"
                              label="Max Selection"
                              component={renderInputField}
                            />
                          </div>
                          <div className="my-1">
                            <Field
                              name="max_pages"
                              label="Max Pages"
                              component={renderInputField}
                            />
                          </div>
                          <div className="my-1">
                            <Field
                              name="csv_max_upload_rows"
                              label="CSV Enrichment Max Rows"
                              component={renderInputField}
                            />
                          </div>
                          <div className="my-1">
                            <Field
                              name="csv_max_upload_cols"
                              label="CSV Enrichment Max Cols"
                              component={renderInputField}
                            />
                          </div>
                          <div className="my-1">
                            <Field
                              name="csv_max_upload_size"
                              label=" CSV Enrichment Max File Size(MB)"
                              component={renderInputField}
                            />
                          </div>
                          <div className="my-1">
                            <Field
                              name="crm_availablity"
                              label="Crm Available?"
                              component={renderCheckbox}
                              type="checkbox"
                            />
                          </div>

                          <div className="my-1">
                            <Field
                              name="csvexport_availability"
                              label="CSV Export Available?"
                              component={renderCheckbox}
                              type="checkbox"
                            />
                          </div>
                          <div className="my-1">
                            <Field
                              name="extension_availability"
                              label="Extension Available?"
                              component={renderCheckbox}
                              type="checkbox"
                            />
                          </div>
                          <div className="my-1">
                            <FieldArray
                              name="new_features_json"
                              component={renderFieldtd}
                            />
                          </div>
                          {/* ----- footer ----- */}
                          <footer className="flex flex-col items-center justify-center px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50">
                            <button
                              type="submit"
                              className="px-5 py-3 min-w-update-btn font-medium leading-5 transition-colors duration-150  border border-transparent rounded-lg bg-green-1000 text-white active:bg-green-1000 hover:bg-green-1001 focus:outline-none"
                            >
                              {processLoading ? (
                                <CustomSpinner />
                              ) : (
                                <span>{LocalStrings.button_save}</span>
                              )}
                            </button>
                          </footer>
                        </form>
                      </>
                    </div>
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  // ----- initial value for redux form
  let initial = {};
  //console.log("state", state);
  // ----- setting initial value
  if (
    state.editPlanModal &&
    state.editPlanModal.data &&
    state.editPlanModal.data.planid
  ) {
    let new_features_json = JSON.parse(
      state.editPlanModal.data.new_features_json
    );

    initial.planid = state.editPlanModal.data.planid;
    initial.validity = state.editPlanModal.data.validity;
    initial.details = state.editPlanModal.data.details;
    initial.contactcredit = state.editPlanModal.data.contactcredit;
    initial.personal_email_credits =
      state.editPlanModal.data.personal_email_credits;
    initial.bus_email_credits = state.editPlanModal.data.bus_email_credits;

    new_features_json = new_features_json.map(({ id, ...rest }) => rest);
    initial.new_features_json = new_features_json;

    initial.technology_credits = state.editPlanModal.data.technology_credits;
    initial.api_rate_min = state.editPlanModal.data.api_rate_min;
    initial.api_rate_hr = state.editPlanModal.data.api_rate_hr;
    initial.api_rate_day = state.editPlanModal.data.api_rate_day;
    initial.csv_enr_day = state.editPlanModal.data.csv_enr_day;

    initial.basicfilters_limits = state.editPlanModal.data.basicfilters_limits;
    initial.max_selection = state.editPlanModal.data.max_selection;
    initial.max_pages = state.editPlanModal.data.max_pages;
    initial.api_rate_monthly = state.editPlanModal.data.api_rate_monthly;

    initial.csv_max_upload_rows = state.editPlanModal.data.csv_max_upload_rows;
    initial.csv_max_upload_cols = state.editPlanModal.data.csv_max_upload_cols;
    initial.csv_max_upload_size = state.editPlanModal.data.csv_max_upload_size;
    initial.csvexport_availability =
      state.editPlanModal.data.csvexport_availability;
    initial.extension_availability =
      state.editPlanModal.data.extension_availability;
    initial.crm_availablity = state.editPlanModal.data.crm_availablity;
  }
  //console.log("initial", initial);

  return {
    session: state.session,
    editPlanModal: state.editPlanModal,
    initialValues: initial,
    partnerProgramReducer: state.partnerProgramReducer,
  };
};

const validate = (values, ownProps) => {
  //console.log("ownProps", ownProps);
  let errors = {};

  return errors;
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editPlanModalOpen,
      updatePlan,
      fetchEnterpriseData,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "EditPlanForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: false,
  })(EditPlan)
);
