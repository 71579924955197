import _ from "lodash";
import { INSTANCE_URL, TABLE_URL } from "../../Common/constant";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from "../../Common/network-call";
import { reset } from "redux-form";

export var ENTERPRISE_PLAN = "ENTERPRISE_PLAN";
export var EDIT_PLAN_MODAL = "EDIT_PLAN_MODAL";

export function fetchEnterpriseData(session, callback) {
  // console.log("session", session);
  let filter = encodeURI(`(isaddon=0)`);

  var url = `${TABLE_URL}/subscriptionplan?filter=${filter}&order=planid%20ASC`;
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        console.log("get", data);
        dispatch({
          type: ENTERPRISE_PLAN,
          payload: { data: data.resource },
        });
        callback({ success: 1, data: data });
      },
      (error) => {
        console.log("error", error);
      }
    );
  };
}

export function updatePlan(session, values, planid, callback) {
  var url = `${TABLE_URL}/subscriptionplan/${planid}`;
  // console.log("values updatePlan", values);
  // console.log("url updatePlan", url);

  return (dispatch) => {
    patchRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        console.log("response", response);
        callback({ success: 1 });
      },
      (error) => {
        console.log("error", error);
        callback({ success: 0, message: error });
      }
    );
  };
}

export const editPlanModalOpen = (values) => {
  return (dispatch) => {
    dispatch({ type: EDIT_PLAN_MODAL, payload: values });
  };
};
