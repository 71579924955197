import { ENTERPRISE_PLAN, EDIT_PLAN_MODAL } from "../actions";

export var enterPrisePlan = (state = {}, action) => {
  switch (action.type) {
    case ENTERPRISE_PLAN:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

export var editPlanModal = (state = {}, action) => {
  switch (action.type) {
    case EDIT_PLAN_MODAL:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
