import React, { useState, useEffect, useRef, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { Dialog, Transition } from "@headlessui/react";
import { Field, reduxForm, FieldArray } from "redux-form";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

// constants
import {
  renderInputField,
  renderCheckbox,
  CustomSpinner,
  renderInputFieldTextArea,
} from "../Common/base-component";
import { DATE_FORMAT } from "../Common/constant";
import LocalStrings from "../../languages/LocalStrings";
import { Toastify } from "./Toastify";

// actions
import { setAddCodeModalDetails, logout } from "../../actions";
import { updatePlan, fetchEnterpriseData } from "../plan/actions";
import { updateStatus, fetchReportedList } from "../reportedcontact/actions";
// components

// files
import Cross from "../../assets/img/icons/cross-icon.svg";

const CommonModal = (props) => {
  const { session, commonModalDetails, handleSubmit } = props;
  const [processLoading, setProcessLoading] = useState(false);
  const [refundLoading, setRefundLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ message: "" });
  const Navigate = useNavigate();
  //   const [commonModalDetails, props.commonModalDetails({show: false})] = useState(true);
  const [logoutLoading, setLogoutLoading] = useState(false);

  const formSubmit = (type) => {
    if (type == "refund") {
      setRefundLoading(true);
    } else {
      setProcessLoading(true);
    }

    let udt = commonModalDetails.data;
    let values = {};
    values.type = type;
    values.id = udt.id;
    values.status = "approved";
    //console.log("values", values);
    let appuserid = udt.owner == "seluser" ? udt.appuserid : null;

    props.updateStatus(session, values, (feed) => {
      props.fetchReportedList(session, appuserid, 1, (res) => {
        if (values.type == "refund") {
          setRefundLoading(false);
        } else {
          setProcessLoading(false);
        }
        _closeModal();
      });
    });
  };

  const onSubmitFormnew = (values) => {
    setProcessLoading(true);

    let udt = commonModalDetails.data;
    let data = {};
    data.type = "updatecontact";
    data.id = udt.id;
    data.status = "approved";
    data.newvalue = values.newvalue;
    // console.log("data", data);
    // debugger;
    let appuserid = udt.owner == "seluser" ? udt.appuserid : null;

    props.updateStatus(session, data, (feed) => {
      props.fetchReportedList(session, appuserid, 1, (res) => {
        setProcessLoading(false);
        props.reset();
        _closeModal();
      });
    });
  };

  const onSubmitForm = (values) => {
    //console.log("Submitted values:", values);
    let newFeature = [];
    const features = Array.isArray(values.new_features_json)
      ? values.new_features_json
      : [];
    if (features.length > 0) {
      features.map((row, index) => {
        if (row.value && row.name) {
          let obj = { value: row.value, name: row.name };
          //console.log("obj", obj);
          newFeature.push(obj);
        }
      });
    }
    //console.log("newFeature", newFeature);
    if (newFeature.length > 0) {
      values.new_features_json = JSON.stringify(newFeature);

      setProcessLoading(true);
      props.updatePlan(session, values, values.planid, (res) => {
        // console.log("res", res);
        if (res.success === 1) {
          props.fetchEnterpriseData(session, (obj) => {
            setProcessLoading(false);
            console.log("obj", obj);
            Toastify("success", "Successfully updated");
            //_closeModal();
          });
        } else {
          setProcessLoading(false);
          Toastify("error", res.message);
          //_closeModal();
        }
      });
      _closeModal();
    } else {
      Toastify("error", "Feature can not be empty");
    }
  };

  const _closeModal = () => {
    props.setAddCodeModalDetails({ show: false, calledFrom: "" });
  };

  const handleLogout = () => {
    setLogoutLoading(true);
    setTimeout(() => {
      setLogoutLoading(false);
    }, 1400);
    setTimeout(() => {
      _closeModal();
      props.logout(session, (res) => {
        if (res.success) Navigate("/login");
      });
    }, 1500);
  };

  const _renderModalBody = () => {
    if (commonModalDetails.calledFrom === "passcode") {
      return <></>;
    } else if (
      commonModalDetails.calledFrom === "logout" &&
      _.isEmpty(commonModalDetails.data)
    ) {
      return (
        <>
          <div className="">
            <div className="mt-3 text-center sm:mt-5">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {LocalStrings.phrase_logout}
              </Dialog.Title>
            </div>
          </div>
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium shadow-sm focus:outline-none focus:ring-2 bg-kipplobg text-white active:bg-kipplobg hover:bg-kipplohover sm:col-start-2 sm:text-sm"
              onClick={handleLogout}
            >
              {logoutLoading ? <CustomSpinner /> : LocalStrings.button_logout}
            </button>
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
              onClick={_closeModal}
            >
              {LocalStrings.button_cancel}
            </button>
          </div>
        </>
      );
    } else if (
      commonModalDetails.calledFrom === "plan" &&
      !_.isEmpty(commonModalDetails.data)
    ) {
      let plandt = commonModalDetails.data;
      //console.log("plandt", plandt);
      return (
        <>
          <div>
            {plandt.sku == "custom_yearly"
              ? plandt.name + " Yearly"
              : plandt.sku == "custom_monthly"
              ? plandt.name + " Monthly"
              : plandt.name}
          </div>
          <span className="text-sm text-red-800">{errorMessage.message}</span>
          <form className="" onSubmit={handleSubmit(onSubmitForm)}>
            <div className="my-1">
              <Field
                name="details"
                label="Details"
                component={renderInputFieldTextArea}
                placeholder="details"
                mandatory
              />
            </div>
            <div className="my-1">
              <Field
                name="validity"
                label="Validity"
                component={renderInputField}
                placeholder="validity"
                mandatory
              />
            </div>
            <div className="my-1">
              <Field
                name="contactcredit"
                label="Contact Credit"
                component={renderInputField}
                mandatory
              />
            </div>
            <div className="my-1">
              <Field
                name="personal_email_credits"
                label="Personal Email Credit"
                component={renderInputField}
                mandatory
              />
            </div>
            <div className="my-1">
              <Field
                name="bus_email_credits"
                label="Business Email Credit"
                component={renderInputField}
                mandatory
              />
            </div>
            <div className="my-1">
              <Field
                name="technology_credits"
                label="Technology Credit"
                component={renderInputField}
                mandatory
              />
            </div>
            <div className="my-1">
              <Field
                name="api_rate_min"
                label="Api rate(min)"
                component={renderInputField}
                mandatory
              />
            </div>
            <div className="my-1">
              <Field
                name="api_rate_hr"
                label="Api rate(hr)"
                component={renderInputField}
                mandatory
              />
            </div>
            <div className="my-1">
              <Field
                name="api_rate_day"
                label="Api rate(day)"
                component={renderInputField}
                mandatory
              />
            </div>
            <div className="my-1">
              <Field
                name="csv_enr_day"
                label="Csv Enricment Per day Credit"
                component={renderInputField}
                mandatory
              />
            </div>
            <div className="my-1">
              <FieldArray name="new_features_json" component={renderFieldtd} />
            </div>
            {/* ----- footer ----- */}
            <footer className="flex flex-col items-center justify-center px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50">
              <button
                type="submit"
                className="px-5 py-3 min-w-update-btn font-medium leading-5 transition-colors duration-150  border border-transparent rounded-lg bg-green-1000 text-white active:bg-green-1000 hover:bg-green-1001 focus:outline-none"
              >
                {processLoading ? (
                  <CustomSpinner />
                ) : (
                  <span>{LocalStrings.button_save}</span>
                )}
              </button>
            </footer>
          </form>
        </>
      );
    } else if (
      commonModalDetails.calledFrom === "contactmetaapprove" &&
      !_.isEmpty(commonModalDetails.data)
    ) {
      let plandt = commonModalDetails.data;
      const contacttype =
        commonModalDetails.contacttype === "businessemail"
          ? "Work Email"
          : commonModalDetails.contacttype === "personalemail"
          ? "Secondary Email"
          : "Direct Dial";
      return (
        <div className="py-4">
          <span className="text-sm text-red-800">{errorMessage.message}</span>
          <form className="" onSubmit={handleSubmit(onSubmitFormnew)}>
            <div className="my-1">
              <Field
                name="newvalue"
                label={contacttype}
                component={renderInputField}
                placeholder={"Enter " + contacttype}
                mandatory
              />
            </div>
            <footer className="flex flex-col items-center justify-center px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50">
              <button
                type="submit"
                className="px-5 py-3 min-w-update-btn font-medium leading-5 transition-colors duration-150  border border-transparent rounded-lg bg-kipplobg text-white active:bg-kipplobg hover:bg-kipplohover focus:outline-none"
              >
                {processLoading ? (
                  <CustomSpinner />
                ) : (
                  <span>Update Contact & Refund</span>
                )}
              </button>
              <button
                type="button"
                onClick={() => formSubmit("refund")}
                className="px-5 py-3 min-w-update-btn font-medium leading-5 transition-colors duration-150  border border-transparent rounded-lg bg-kipplobg text-white active:bg-kipplobg hover:bg-kipplohover focus:outline-none"
              >
                {refundLoading ? <CustomSpinner /> : <span>Refund</span>}
              </button>
            </footer>
          </form>
        </div>
      );
    }
  };

  const _renderModalHeader = () => {
    if (commonModalDetails.calledFrom) {
      if (
        commonModalDetails.calledFrom === "contactmetaapprove" &&
        !_.isEmpty(commonModalDetails.data)
      ) {
        return <span className="">Approve Contact</span>;
      } else {
      }
    }
  };

  const renderFieldtd = ({ fields, meta: { error, submitFailed } }) => (
    <div className="">
      <div className="flex justify-between items-center">
        <div className=" text-base font-semibold">Feature Lists</div>
        <button
          type="button"
          className="cursor-pointer bg-gray-300 px-2 py-3 rounded text-sm font-semibold"
          onClick={() => fields.push({})}
        >
          +Add more feature
        </button>
        {submitFailed && error && <span>{error}</span>}
      </div>
      {fields.map((rowItem, index) => {
        var currentInxedData = fields.get(index);
        let showDelBtn = index === 0 ? " hidden" : "";
        return (
          <>
            <div key={index} className="flex gap-2 items-center">
              <div className="w-2/5">
                <Field
                  key={`${rowItem}.value`}
                  name={`${rowItem}.value`}
                  placeholder="Value"
                  type="text"
                  component={renderInputField}
                />
              </div>
              <div className=" w-1/2">
                <Field
                  key={`${rowItem}.name`}
                  name={`${rowItem}.name`}
                  placeholder="Name"
                  type="text"
                  component={renderInputField}
                />
              </div>

              <div className={showDelBtn.trim() == "hidden" ? "hidden" : ""}>
                <button type="button" onClick={() => fields.remove(index)}>
                  <svg
                    className="w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    role="img"
                    aria-hidden="true"
                  >
                    <path
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
  //console.log({ commonModalDetails });
  return (
    <>
      <Transition.Root show={commonModalDetails.show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={_closeModal}>
          {/* ----- back drop child ----- */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* ----- modal child ----- */}
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="transform transition-all">
                  {/* ----- actual modal ----- */}
                  {commonModalDetails.show ? (
                    <>
                      <div
                        className="bg-white rounded-lg p-4 text-left
                        overflow-hidden- shadow-xl transform transition-all
                        sm:my-8 lg:mt-20 lg:mb-8 ml-0 md:ml-32 sm:max-w-screen-md
                        sm:w-full sm:p-6"
                      >
                        <header className="grid grid-cols-2">
                          {/* ----- title ----- */}
                          <div className="col-span-1 flex justify-start">
                            <p className="mb-2 text-lg font-semibold text-gray-700">
                              {_renderModalHeader()}
                            </p>
                          </div>

                          {/* ----- close button ----- */}
                          <div className="col-span-1 flex justify-end">
                            <button
                              type="button"
                              className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded hover: hover:text-gray-700"
                              aria-label="close"
                              onClick={_closeModal}
                            >
                              <svg
                                className="w-4 h-4"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                role="img"
                                aria-hidden="true"
                              >
                                <path
                                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                  clipRule="evenodd"
                                  fillRule="evenodd"
                                ></path>
                              </svg>
                            </button>
                          </div>
                        </header>
                        {/* ----- rendering modal body anr footer section conditionally ----- */}
                        {_renderModalBody()}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden- shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                        {_renderModalBody()}
                      </div>
                    </>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  // ----- initial value for redux form
  let initial = {};
  //console.log("state", state);
  // ----- setting initial value
  if (
    state.commonAddModalReducer &&
    state.commonAddModalReducer.data &&
    state.commonAddModalReducer.data.planid
  ) {
    let new_features_json = JSON.parse(
      state.commonAddModalReducer.data.new_features_json
    );

    initial.planid = state.commonAddModalReducer.data.planid;
    initial.validity = state.commonAddModalReducer.data.validity;
    initial.details = state.commonAddModalReducer.data.details;
    initial.contactcredit = state.commonAddModalReducer.data.contactcredit;
    initial.personal_email_credits =
      state.commonAddModalReducer.data.personal_email_credits;
    initial.bus_email_credits =
      state.commonAddModalReducer.data.bus_email_credits;

    new_features_json = new_features_json.map(({ id, ...rest }) => rest);
    initial.new_features_json = new_features_json;

    initial.technology_credits =
      state.commonAddModalReducer.data.technology_credits;
    initial.api_rate_min = state.commonAddModalReducer.data.api_rate_min;
    initial.api_rate_hr = state.commonAddModalReducer.data.api_rate_hr;
    initial.api_rate_day = state.commonAddModalReducer.data.api_rate_day;
    initial.csv_enr_day = state.commonAddModalReducer.data.csv_enr_day;
  }
  //console.log("initial", initial);

  if (
    state.commonAddModalReducer &&
    state.commonAddModalReducer.data &&
    state.commonAddModalReducer.data.reasonnote
  ) {
    initial.newvalue = state.commonAddModalReducer.data.reasonnote;
  }
  return {
    session: state.session,
    commonModalDetails: state.commonAddModalReducer,
    initialValues: initial,
    partnerProgramReducer: state.partnerProgramReducer,
  };
};

const validate = (values, ownProps) => {
  //console.log("ownProps", ownProps);
  let errors = {};
  if (!values.newvalue) {
    errors.newvalue = LocalStrings.validation_required;
  } else if (ownProps.commonModalDetails.contacttype !== "directdial") {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailPattern.test(values.newvalue)) {
      errors.newvalue = LocalStrings.invalid_email; // Use a custom error message for invalid email
    }
  }
  return errors;
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setAddCodeModalDetails,
      logout,
      updatePlan,
      fetchEnterpriseData,
      updateStatus,
      fetchReportedList,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "AddCommonModalForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: false,
  })(CommonModal)
);
